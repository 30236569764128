/*
TO DO / HELP WITH THE FOLLOWING:
*** = Need help with
** = Not a big deal but would help if implemented
* = I know how to do but might need double checking

1)*** DinoDoodImage Homepage Hover effect (onmousehover)
2)*** Video in the background of Roadmap Section
3)*** Icon in tab
4) ** Mobile Version has a white border on the side
5) ** Mint Button on Homepage
6) ** Roadmap Slideshow instead of GIF
7) ** Roadmap Timeline Phase away as you scroll up
8) ** FAQ Line color change
9) ** Timeline RoadMap border around the bubbles to see how it looks (#656565)
*/



import './App.css';

/* Navigation Bar Assets Start   -----------------------------------------------------------------------------------------------*/
import logoSmall from './Assets/favicon.ico';
import logo from './Assets/Logo.png';
import DinoDoodHQ from './Assets/DinoDoodHQ.png';
import FAQImage from './Assets/FAQHighlighted.png';
import AboutImage from './Assets/AboutHighLighted.png';
import RoadMapImage from './Assets/RoadMapHighlighted.png';
import TeamImage from './Assets/TeamHighlighted.png';
/* Navigation Bar Assets End   -----------------------------------------------------------------------------------------------*/



/* Dinos Assets Start   -----------------------------------------------------------------------------------------------*/
import oneDino from './Assets/94.png';
import twoDino from './Assets/138.png';
import threeDino from './Assets/299.png';
import fourDino from './Assets/292.png';
import blue from './Assets/BlueDino.gif';
import orange from './Assets/OrangeDino.gif';
import DinoCircle from './Assets/mainCirc.png';
/* Dinos Assets End   -----------------------------------------------------------------------------------------------*/

import fallingDinos from './Assets/fallingDinos.mp4';

/* Social Assets Start   -----------------------------------------------------------------------------------------------*/
import twitter from './Assets/twitter.svg';
import twitterWhite from './Assets/twitterWhite.svg';
import discordWhite from './Assets/discordWhite.svg';
import openseaWhite from './Assets/openseaWhite.svg';
import discord from './Assets/discord.svg';
import opensea from './Assets/opensea.svg';
/* Social Assets End   -----------------------------------------------------------------------------------------------*/

/* RoadMap Assets Start   -----------------------------------------------------------------------------------------------*/
import DinoDoodHQRoadMapGIF from './Assets/RoadMap/RoadMap.gif';
import DinoDoodHQRoadMapPNG from './Assets/RoadMap/DinoDoodHQ.png';
import Genesis from './Assets/RoadMap/Genesis.png';
import MainMint from './Assets/RoadMap/Mint.png';
import DinoBank from './Assets/RoadMap/DinoBank.png';
import Merch from './Assets/RoadMap/Merch.png';
import Token from './Assets/RoadMap/Token.png';
import Candy from './Assets/RoadMap/Candy.png';
import Two from './Assets/RoadMap/two.png';
/* RoadMap Assets End   -----------------------------------------------------------------------------------------------*/

/* Team Assets Start   -----------------------------------------------------------------------------------------------*/
import yingster from './Assets/Yingster.png';
import Swag from './Assets/SwagDolphin.png';
import bui from './Assets/Buiitie.png';
import bun from './Assets/BunBo.png';
import jenni from './Assets/Jenni.png';
import banana from './Assets/Banana.png';
/* Team Assets End   -----------------------------------------------------------------------------------------------*/
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

import Contract from './Assets/Contract.json';
import  { ethers } from 'ethers';

const data = {
 
  rows: [
      {
          title: "What is the total supply?",
          content: `The total supply for DinoDoods Collection is 5,555 dinos. The total supply for Dino Genesis is 375 incubators.`,
      },
      {
          title: "What is the mint price?",
          content:
              "Genesis Collection Price will be 0.065 ETH allowing WL to mint 2 per wallet.",
      },
      {
          title: "When is DinoDoods Launch?",
          content: "Dino Genesis will launch April 28th 2022 @ 12PM CENTRAL! The launch for DinoDood main collection is to be announced!",
      },
      {
        title: "What is the utility of DinoDoods?",
        content: "Our Genesis Collection provides use of tokenomics that will be able to earn $Fossyl which can be used to purchase a free mint for the main collection! If you obtain an NFT from our main collection, it can be staked to also generate $Fossyl which will be essential to minting future collections and assets! Both collections will also gain extra benefits within Discord!"
      },
      {
        title: "How do I get whitelist?",
        content: "To obtain whitelist is simply having fun within our server! Whether it's through general chat, events or hopping in VC! Another way to earn WL is through giveaways, competitions, and raffles!"
      },

  ],
};


function App() {

  const [walletAccount, setWalletAccount] = useState(null);

  const getProof = () => {

    const Wallets = require('./Assets/wallets.json');
    const { MerkleTree } = require('merkletreejs'); 
    const keccak256 = require('keccak256');

    const leafnodes = Wallets.map(address => {
      return keccak256(address)
    });
    const merkletree = new MerkleTree(leafnodes, keccak256, { sortPairs: true });
    return merkletree.getHexProof(keccak256(walletAccount));
  }
    
  const connect = async () => {
    const { ethereum } = window;
    if (ethereum) {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(accounts);
      if (accounts.length !== 0) {
        setWalletAccount(accounts[0]);
      } else {
        console.log("Could not connect to wallet");
      }
    }
  };

  const mint = async() => {
    try {
      const { ethereum } = window;
      const CONTRACT_ADDRESS = '0x464007FDb91f0c0A535083421d86598a5aeF5966';
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, Contract.abi, signer);
        const proof = getProof();
        let transaction = await connectedContract.mintPresale(proof, {value: ethers.utils.parseEther(".065")});
        await transaction.wait();
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${transaction.hash}`);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const mint_nft = () => {
    connect();

    if(walletAccount) {
      mint();
    }
  }

  return (
    
  /* Header Start   -----------------------------------------------------------------------------------------------*/
    <div className="Header">
     
<section className = "Nav Bar Start --------------------------------------------------------------------------------"></section>
        <div className = "NavBar">
            <table className = "TableNavBar">
              <tbody>
                  <tr>
                      <td>
                      <div className = "DropDown">
                        <button className = "dropdownButton">More Here!</button>
                        <div className = "DropdownContent">
                        <a href = "https://www.dinodoodsmint.com/">Mint</a>
                        <a href = "https://twitter.com/dinodoods">Twitter</a>
                        <a href = "https://opensea.io/collection/dinodoods-genesis">OpenSea</a>
                        </div>
                        
                       </div>

                      </td>
                      <td>
                     
                      </td>
                      <td class = "logo">
                        <a href = "#home">
                          <img className = "Logo" src ={logo}></img>
                        </a>
                      </td>
                        
                      <td class = "links">
                        <a className = "LinkButtonNav" href = "#about">About</a>
                        <a className = "LinkButtonNav" href = "#roadmap">RoadMap</a>
                        <a className = "LinkButtonNav" href = "#team">Team</a>
                        <a className = "LinkButtonNav" href = "#faq">FAQ</a>
                        <a className = "LinkButtonNav" href = "https://www.dinodoodsmint.com/">Mint</a>
                   
                      </td>
                      
                      <td class = "socials">
                           <a href='https://twitter.com/dinodoods'><img src ={twitter} className = "mobileSocials" alt ="Twitter"/></a>
                           <a href='https://discord.gg/dinodoods'><img src ={discord} className = "mobileSocials" alt ="Discord"/></a>
                           <a href='https://opensea.io/collection/dinodoods-genesis'><img src ={opensea} className = "mobileSocials" alt ="OpenSea"/></a>
                      </td>   

                                  
                  </tr>
              </tbody>
            </table>
            

           
           
           
          </div>
<section className = "Nav Bar END --------------------------------------------------------------------------------"></section>


<section className = "DinoDoods Header Image Start --------------------------------------------------------------------------------"></section>

      <div className = "DinoDoodHQ" id = "home">
    
      <a href = "#team" className = "bubbleGum"></a>
      <a href = "#faq" className = "FAQTable"></a>
      <a href = "#about" className = "AboutTable"></a>
      <a href = "#roadmap" className = "Map"></a>

      
      <img className = "DinoDoodHQImage" id ="HomePageImage" src ={DinoDoodHQ}></img>

      
      </div>
      
      
<section className = "DinoDoods Header Image END --------------------------------------------------------------------------------"></section>



<section className = "About Section Start --------------------------------------------------------------------------------"></section>

      <div className = "AboutSection" id = "about">

        <div className = "AboutTitleGroup">
        <p className = "AboutTitle">About</p>
        </div>
       
        <p className = "AboutFirst">
        We are DinoDoodHQ! A place where we provide cute and loving dinosaurs to our community, the doods. 
        Our genesis contains 
        375 animated dino incubators and our main collection consists of 5,555 dinos with over 150+ traits!
        </p>
    
         
         <div className = "DinoGallery">
          <img className = "DinoImage" src ={oneDino}></img>
          <img className = "DinoImage" src ={twoDino}></img>
          <img className = "DinoImage" src ={threeDino}></img>
          <img className = "DinoImage" src ={fourDino}></img>
          </div>
          
          <p className = "PurpleTitle">Mission</p>

          <div className = "MissionSection">
            <img className = "smol" src ={orange}></img>
            <div className = "OurMission">
              <p>DinoDoodHQ wants to create a friendly ecosystem and a homebase for the doods. DinoDoods is a space to provide the basics 
                to NFTs, research tips, and to stay safe in Web3.
                 Everyone is welcome in DinoDoods, from hatchings wanting to be T-rexes or just genuine art lovers, 
                 we want to help create the best path for all to succeed.</p>
              <p>With this there is also opportunity to grow close bonds with people along the way. </p>
              <p>Our team is composed of artists, nerds, entrepreneurs, and gamers! With many diverse personalities throughout our team, 
                we want to connect with people of similar and outside interest while growing our community.</p>
            </div>
            <img className = "smolBlue" src ={blue}></img>
          </div>
          <img className = "dinoCircle" src ={DinoCircle}></img>
      </div>

     
<section className = "About Section END --------------------------------------------------------------------------------"></section>

<section className = "RoadMap Section Start --------------------------------------------------------------------------------"></section>


<div className = "RoadmapSection" id ="roadmap">
    <p className = "PurpleTitle" id = "roadmapTitle">RoadMap</p>

    <div className ="RoadMapImageSection">
      

      <img className = "active" id ="RoadMapImage" src= {DinoDoodHQRoadMapGIF}></img>

      
      
     
     </div>

    </div>



    <video autoPlay loop muted id="video">
        <source src={fallingDinos} type="video/mp4"></source>
      </video>
      
      

      <VerticalTimeline className = "timeline">

          <VerticalTimelineElement
          class = "vertical-timeline-element-work"
          contentStyle={{background: '#D1ADFF', color: '#fff'}}
          contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
          iconStyle ={{background: '#D1ADFF', color: '#fff'}}
          date = ""
          dateClassName= "DateAttribute"
        
          icon={''}>
            <h3 className= "vertical-timeline-element-title">Genesis</h3>
            <h4 className= "vertical-timeline-element-subtitle">Be apart of Dino Scholars by adopting an Incubator! DinoDood Genesis
            is a 375 animated collection with different attributes and varients.</h4>
            <br></br>
            <h4 className= "vertical-timeline-element-subtitle"><u>Benefits include:</u> Special channel perks into discord, exclusive DinoDood Plushie voted by the community,
            exclusive access to giveaways, perks and more! Tokenomics will also be implemented as mentioned in our roadmap!</h4>
            <br></br>
            <h3 className= "vertical-timeline-element-title"><u>April 28th 2022 @ 12PM Central</u></h3>
          </VerticalTimelineElement>


          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#D1ADFF', color: '#fff'}}
            contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
            date=""
            iconStyle={{ background: '#D1ADFF', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title">Main Collection</h3>
            <h4 className="vertical-timeline-element-subtitle">5,555 Dinos with over 150+ unique traits! Be apart of the Dood Fam and tame some Dinos!
            Perks for holders will be implmented throughout our roadmap.</h4>
            <h3 className= "vertical-timeline-element-title"><u>To Be Announced</u></h3>
          </VerticalTimelineElement>

          <VerticalTimelineElement
          class = "vertical-timeline-element-work"
          contentStyle={{background: '#D1ADFF', color: '#fff'}}
          contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
          iconStyle ={{background: '#D1ADFF', color: '#fff'}}
          date = ""
          icon={''}>
            <h3 className= "vertical-timeline-element-title">Tokenomics</h3>
            <h4 className= "vertical-timeline-element-subtitle">Get ready for Dino Tokens! Genesis will yield 3 $Fossyl per day which will be used throughout the Dinodoods ecosystem, one of which will be to mint the free Dino from the main collection!
              Main collection will yield 5 $Fossyl per day</h4>
            <h3 className= "vertical-timeline-element-title"><u>To Be Announced</u></h3>
          </VerticalTimelineElement>

          <VerticalTimelineElement
          class = "vertical-timeline-element-work"
          contentStyle={{background: '#D1ADFF', color: '#fff'}}
          contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
          iconStyle ={{background: '#D1ADFF', color: '#fff'}}
          date = ""
          icon={''}>
            <h3 className= "vertical-timeline-element-title">Dino Bank</h3>
            <h4 className= "vertical-timeline-element-subtitle">% of Mint will go towards the DinoBank. This is the community bank that holds all goodies and rewards
            to fund community events, the dinodoods ecosystem, and giveaways.</h4>
            <h3 className= "vertical-timeline-element-title"><u>After Mintout</u></h3>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#D1ADFF', color: '#fff'}}
            contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
            date=""
            iconStyle={{ background: '#D1ADFF', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title">Merch</h3>
            <h4 className="vertical-timeline-element-subtitle">Rep DinoDoods by obtaining some merch! This may include things like: plushies, clothing and or mugs. </h4>

            <h3 className= "vertical-timeline-element-title"><u>To Be Announced</u></h3>
          </VerticalTimelineElement>

         

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#D1ADFF', color: '#fff'}}
            contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
            date=""
            iconStyle={{ background: '#D1ADFF', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title">Candy and Rewards</h3>
            <h4 className="vertical-timeline-element-subtitle">Supply of Dino Candy will stock up for the Doods as well as rewards! Rewards may include: ETH,
            giveaways and more! What is Dino Candy? More details will be revealed soon...</h4> 
            <h3 className= "vertical-timeline-element-title"><u>To Be Announced</u></h3>
          </VerticalTimelineElement>

          <VerticalTimelineElement
          class = "vertical-timeline-element-work"
          contentStyle={{background: '#D1ADFF', color: '#fff'}}
          contentArrowStyle ={{borderRight: '7px solid  #D1ADFF'}}
          iconStyle ={{background: '#D1ADFF', color: '#fff'}}
          date = ""
          icon={''}>
            <h3 className= "vertical-timeline-element-title">DinoDoodHQ Phase 2.0</h3>
            <h4 className= "vertical-timeline-element-subtitle">Coming Soon...</h4>
          </VerticalTimelineElement>


      </VerticalTimeline>

<section className = "RoadMap Section END --------------------------------------------------------------------------------"></section>


<section className = "Team Section Start --------------------------------------------------------------------------------"></section>
       <div id = "team">
        
         
          <p className = "PurpleTitle" id = "TeamTitle">Team</p>

          
        <div className="TeamFull">
                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage" src = {yingster}></img>
                      <div className = "descTitle">Yingster</div>
                      <div className = "desc">Co-Founder / Business Analysis</div>
                      <a href='https://twitter.com/Yingster25'><img className = "imageSocial"src = {twitterWhite}></img></a>
                  </div>
                </div>
                
                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage" src = {bui}></img>
                      <div className = "descTitle">Buiitie</div>
                      <div className = "desc">Co-Founder / Artist / Web Dev</div>
                      <a href='https://twitter.com/buiitie'><img className = "imageSocial"src = {twitterWhite}></img></a>
                  </div>
                </div>

                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage" src = {Swag}></img>
                      <div className = "descTitle">SwagDolphin</div>
                      <div className = "desc">Project Advisor</div>
                      <a href='https://twitter.com/SwagDolphinn'><img className = "imageSocial"src = {twitterWhite}></img></a>
                  </div>
                </div>
                
                
                <div class="clearfix"></div>
          </div>

          <div className="TeamFull">
                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage"src = {bun}></img>
                      <div className = "descTitle">BunBoHue</div>
                      <div className = "desc">Developer</div>
                  </div>
                </div>
                
                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage" src = {jenni}></img>
                      <div className = "descTitle">Jenni In A Bottle</div>
                      <div className = "desc">Web Helper</div>
                  </div>
                </div>

                <div className = "responsive">
                  <div className= "TeamGallery">
                      <img className = "pfpImage" src = {banana}></img>
                      <div className = "descTitle">Banana Sir</div>
                      <div className = "desc">Community Manager</div>
                  </div>
                </div>
                
                
                <div class="clearfix"></div>
          </div>
        </div>
<section className = "Team Section END --------------------------------------------------------------------------------"></section>

<section className = "FAQ Section Start --------------------------------------------------------------------------------"></section>
<p className = "PurpleTitle" id = "faq">Frequently Asked Questions</p>

      <div className="FAQSection">
          <Faq 
              data={data} 
              styles={{
                  titleTextColor: "black",
                  rowTitleColor: "black",
                  rowContentColor: "black",
                  
                  
                  rowTitleTextSize: '250%',
                  rowContentTextSize: '200%',
                  rowContentPaddingTop: '10px',
                  rowContentPaddingBottom: '10px',
                  rowContentPaddingLeft: '10px',
                  arrowColor: "black",

                  transitionDuration: ".3s",
                  timingFunc: "linear",

                  bgColor: 'none',
                  
              }} 
              config={{
                  arrowIcon: "♡",
                  tabFocus: true,
              }}
          />

          </div>
           
<section className = "FAQ Section END --------------------------------------------------------------------------------"></section>


      </div>/* Header End   -----------------------------------------------------------------------------------------------*/
  );  
}

export default App;
